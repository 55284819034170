<template>
  <div class="page-table listPage mainDiv" id="affix-container">
    <div class="card-base baslikBg animated fadeInUp">
      Sözleşmeler
      <div class="tanimlamaButon">
        <el-tooltip :content='$t("src.views.apps.genel.refresh")' :open-delay="500" placement="top">
          <el-button v-on:click="refreshPage()" size="mini" type="text" icon="el-icon-refresh"></el-button>
        </el-tooltip>
      </div>
    </div>
    <el-row :gutter="10">
      <el-form status-icon :model="surecForm" :rules="rulesMenu" ref="surecForm" class="demo-ruleForm">
        <el-col :lg="18" :md="18" :sm="24" :xs="24">
          <div class="page-header card-base card-shadow--medium animated fadeInUp" v-loading="loading || stateLoading"
               :element-loading-text="loading ? 'Sözleşme Listesi Getiriliyor. Lütfen Bekleyiniz.' : $t('src.views.apps.menu.deleteLoading')"
               element-loading-spinner="el-icon-loading"
               element-loading-background="rgba(255, 255, 255, 1)">
            <!--                <el-form-item label="Sözleşme Kategori :">-->
            <!--                    <el-select v-model="sozlesmeKategori" placeholder="Sözleşme Kategori Seçiniz" size="small"  @change="handleChangeSozlesmeType" class="mb-1">-->
            <!--                        <el-option value="1" label="BG Proje"></el-option>-->
            <!--                        <el-option value="2" label="BG İnşaat"></el-option>-->
            <!--                        <el-option value="3" label="BG Prefabrik"></el-option>-->
            <!--                        <el-option value="4" label="BG Çelik"></el-option>-->
            <!--                        <el-option value="5" label="BG Panel"></el-option>-->
            <!--                        <el-option value="6" label="BG Lojistik"></el-option>-->
            <!--                        <el-option value="7" label="BG Enerji"></el-option>-->
            <!--                    </el-select>-->
            <!--                </el-form-item>-->


            <el-col :lg="24" :md="24" :sm="24" :xs="24">
              <div style="font-style: italic;">
                <i class="mdi mdi-information-outline mdi-18px" style="margin-right: 5px;"></i>
                Sözleşme konumlarını sürükleyerek veya butona tıkladığınızda açılan yan menüden değiştirebilirsiniz.
              </div>
            </el-col>
            <el-row class="mt-10">
              <el-col :lg="24" :md="24" :sm="24" :xs="24">
                <nested-draggable v-model="ekrandakiSiralama" :key="refreshSozlesme" :sozlesmeList="sozlesmeList"
                                  @show-collapse="showCollapse"/>
              </el-col>
            </el-row>
          </div>
        </el-col>
        <el-col :lg="6" :md="6" :sm="24" :xs="24" v-loading="addLoading || updateLoading"
                :element-loading-text="addLoading ? $t('src.views.apps.menu.addLoading'):  $t('src.views.apps.menu.updateLoading')"
                element-loading-spinner="el-icon-loading"
                element-loading-background="rgba(255, 255, 255, 1)">
          <div>

            <el-collapse v-if="collapseVisible" v-model="activeCollapse" class="yanForm animated fadeInUp">
              <el-collapse-item class="card-shadow--medium " name="8" title="Sözleşme Sıralaması Düzenle">
                <el-row>
                  <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                    <el-form-item
                        :label='$t("src.views.apps.surecTakip.sozlesmeBaslik")'
                        prop="sozlesmaBaslik">:
                      {{ this.selectedSozlesme.sozlesmeBaslik }}
                    </el-form-item>
                  </el-col>
                </el-row>

                <el-row>
                  <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                    <el-form-item
                        :label='$t("src.views.apps.surecTakip.siradakiSozlesme")'
                        prop="sozlesmaBaslik">:
                      <el-select style="width: 100%" size="small" v-model="surecForm.sozlesmeID"
                                 placeholder="Sözleşme Seçiniz" filterable :filter-methos="filterMethod">
                        <el-option
                            v-for="item in secilenSozlesmeListe"
                            :key="item.sozlesmeID"
                            :label="item.sozlesmeBaslik"
                            :value="item.sozlesmeID">
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>

              </el-collapse-item>
            </el-collapse>
            <el-collapse v-model="activeCollapse" class="yanForm animated fadeInUp"
                         :style="{'padding-top': collapseVisible ? '10px' : 0}">
              <el-collapse-item class="card-shadow--medium " name="8" title="Kaydet">

                <el-row>
                  <el-button size="mini" v-on:click="sozlesmeSiraEkle('surecForm', surecForm.yoneticiID)"
                             class="onayBtn" type="primary">
                    <label>Kaydet</label>
                  </el-button>
                  &nbsp;
                  <el-popover placement="bottom" width="320" v-model="visibleSil">
                    <p>{{ $t('src.views.apps.messages.clearFormAlert') }}</p>
                    <div style="text-align: right; margin: 0">
                      <el-button size="mini" type="primary" @click="resetForm('surecForm')">
                        {{ $t('src.views.apps.genel.yes') }}
                      </el-button>
                      <el-button size="mini" @click="visibleSil = false;">
                        {{ $t('src.views.apps.genel.no') }}
                      </el-button>
                    </div>
                    <el-button size="mini" slot="reference">
                      {{ $t('src.views.apps.genel.vazgec') }}
                    </el-button>
                  </el-popover>
                </el-row>
              </el-collapse-item>
            </el-collapse>
          </div>
        </el-col>
      </el-form>
    </el-row>
  </div>
</template>

<script>
import nestedDraggable from "../../../components/NestedDraggable.vue";
import menuService from "../../../WSProvider/MenuService";
import sozlesmeService from '../../../WSProvider/SozlesmeService';
import notification from "../../../notification";
import functions from "../../../functions";
import EventBus from '@/components/event-bus'
import iconJson from '../../../icon'
import Jquery from "jquery";

const $ = Jquery;

export default {
  components: {nestedDraggable},
  display: "Nested",
  order: 15,
  watch: {
    sozlesmeList(val) {
      EventBus.$emit("sozlesmeListGuncelle", val);
    },
  },
  data() {
    return {
      sozlesmeKategori: "1",
      selectedSozlesme: "",
      //Onay dialog
      dialogVisible: false,
      update: false,
      iconListDialog: false,
      collapseVisible: false,
      visibleSil: false,

      //draggable
      sozlesmeList: [],
      sozlesmeListe: [],
      secilenSozlesmeListe: [],
      ekrandakiSiralama: [],
      sozlesmeListCopy: [],
      filterMenuKategori: "",
      altMenulerListe: [],
      refreshSozlesme: 0,
      iconListe: iconJson["v5.0.9"],

      //menu ekleme form
      surecForm: {
        menuID: "",
        baslik: "",
        menuSayfalar: [],
        sozlesmeID: "",
        sozlesmeler: [],
        allMenuList: [],
        sozlesmeYeni: [],
        menudeOlanSayfalar: [],
        ustMenu: {},
        seciliSayfa: {},
        sozlesmeKategoriID: "",
        seciliMenuKategori: "",
        menuGorunum: "",
        icon: "",
        oncekiDurumID: "",
        sonrakiDurumID: ""
      },

      loading: false,
      stateLoading: false,
      updateLoading: false,
      addLoading: false,
      selectIcon: "1",
      activeCollapse: ["1", "2", "3", "4", "5", "6", "7", "8", "9"],


      rulesMenu: {
        baslik: [{required: true, message: "Lütfen Başlık Giriniz", trigger: "blur"}],
        menuGorunum: [{required: true, message: "Lütfen Menü Konumu Seçiniz", trigger: "blur"}],
        ustMenu: [{required: true, message: "Lütfen Üst Menü Seçiniz", trigger: "blur"}],
        seciliSayfa: [{required: true, message: "Lütfen Menü Konumu Seçiniz", trigger: "blur"}],
        // sozlesmeKategoriID: [{required: true, message: "Lütfen Menü Kategori Seçiniz", trigger: "blur"}],
      },
    }
  },
  created() {
    this.getSozlesmeList();
    const self = this;
    EventBus.$on("sozlesmeListStartUpdate", function (value) {
      self.startUpdate(value);
    })
  },
  methods: {
    filterMethod(query, item) {
      return item.label.toLowerCase().indexOf(query.toLowerCase()) >= 0;
    },
    refreshPage() {
      this.loading = false;
      this.stateLoading = false;
      this.addLoading = false;
      this.updateLoading = false;
      this.collapseVisible = false;
      this.getSozlesmeList();
    },
    showCollapse(sozlesme) {
      this.selectedSozlesme = sozlesme;
      //console.log("showCollapse")
      //console.log(this.selectedSozlesme)
      /*
      if(this.selectedSozlesme.sozlesmeKategoriID == 1) {
          this.secilenSozlesmeListe = this.sozlesme
      }*/
      this.collapseVisible = true;
      this.sozlesmeList.forEach(grup => {
        grup.sozlesmeKategoriID == this.selectedSozlesme.sozlesmeKategoriID ? this.secilenSozlesmeListe = grup.sozlesmeler : ""
      })


      /*
                      this.sozlesmeList.forEach((grup) => {
                          console.log("grup")
                          console.log(grup)
                          console.log(grup.sozlesmeKategoriID)
                          const grupListe = grup.findIndex((grupID) => grupID.sozlesmeKategoriID == this.selectedSozlesme.sozlesmeKategoriID)
                          console.log(grupListe)
                      })*/
    },
    handleChangeSozlesmeType(val) {
      this.getSozlesmeList(val);
    },
    startUpdate(value) {
      this.surecForm.sozlesmeList = editSozlesmeList(this.sozlesmeList)
      this.update = true;
      this.surecForm.menuID = value.menuID;
      this.surecForm.baslik = value.baslik;
      this.surecForm.icon = value.icon;
      this.surecForm.ustMenu = value.ustMenuID;
      this.surecForm.menuGorunum = value.ustMenuID != "0" ? "1" : "0";
      this.surecForm.sozlesmeKategoriID = value.sozlesmeKategoriID;
    },
    resetForm() {
      this.visibleSil = false
      this.collapseVisible = false
      this.$refs["surecForm"].resetFields();
    },
    clearForm(formName) {
      this.$refs[formName].resetFields();
      this.surecForm.menuGorunum = "0";
      this.surecForm.sozlesmeKategoriID = "";
      this.update = false;
    },
    getSozlesmeList() {
      try {
        this.loading = true;
        sozlesmeService.sozlesmeListesi(1, "", "").then(response => {
          if (response.status === 200) {
            localStorage.setItem("userDataBGSurec", response.token);
            response.data.forEach(el => {
              el = functions.removeSlashesAndDecodeStrings(el)
            })
            this.sozlesmeListe = [...response.data];

            //console.log(this.sozlesmeListe)

            const gruplanmisSozlesmeler = [];
            this.sozlesmeListe.forEach((sozlesme) => {
              const sozlesmeKategoriAdi = sozlesme.sozlesmeKategoriAdi;
              const sozlesmeKategoriID = sozlesme.sozlesmeKategoriID;
              const grupIndex = gruplanmisSozlesmeler.findIndex((grup) => grup.sozlesmeKategoriAdi === sozlesmeKategoriAdi);
              if (grupIndex !== -1) {
                gruplanmisSozlesmeler[grupIndex].sozlesmeler.push(sozlesme);
              } else {
                gruplanmisSozlesmeler.push({
                  sozlesmeKategoriAdi: sozlesmeKategoriAdi,
                  sozlesmeKategoriID: sozlesmeKategoriID,
                  sozlesmeler: [sozlesme]
                });
              }
            });
            this.sozlesmeList = gruplanmisSozlesmeler

            this.sozlesmeListCopy = [...response.data];
            //this.getSubSozlesme()
          }
          this.loading = false;
        }).catch(err => {
          if (err.responseJSON) {
            let error = err.responseJSON
            if (error.status == 401) {
              notification.Status(150, this);
            } else if (error.status == 404) {
              this.sozlesmeList = [];
              this.sozlesmeListCopy = [];
            } else notification.Status("errorMsg", this, error.errMsg)
          }
          this.loading = false;
        })
      } catch (e) {
        this.loading = false;
      }
    },
    sozlesmeSiraEkle(formName) {
      this.$refs[formName].validate((valid, errors) => {
        if (valid) {
          this.$confirm("Sözleşme sıralama tanımlamak istediğinize emin misiniz?", this.$t("src.views.apps.genel.uyari"), {
            confirmButtonText: this.$t("src.views.apps.genel.yes"),
            cancelButtonText: this.$t("src.views.apps.genel.no"),
            type: 'warning'
          }).then(() => {
            this.loading = true;
            const sozlesmeSirasi = [];
            let result = editSozlesmeList(this.sozlesmeList);

            //  console.log(result)
            /*
            for (let i = 0; i < this.sozlesmeListe.length; i++) {
                const oncekiSozlesmeID = i == 0 ? 0 : this.sozlesmeListe[i - 1].sozlesmeID;
                const sonrakiSozlesmeID = i == this.sozlesmeListe.length - 1 ? 99 : this.sozlesmeListe[i + 1].sozlesmeID;
                sozlesmeSirasi.push({ oncekiSozlesmeID, sonrakiSozlesmeID });
            }*/
            sozlesmeService.sozlesmeSiraEkle(JSON.stringify(result)).then((response) => {
              if (response.status == 200) {
                localStorage.setItem("userDataBGSurec", response.token)
                this.resetForm(formName)
                EventBus.$emit("sozlesmeList", true)
                functions.sayfaKapat("surectakiplistesi", this);
              }
              this.loading = false;
              notification.Status("success", this, response.msg)
            }).catch(err => {
              if (err.responseJSON) {
                let error = err.responseJSON
                if (error.status == 401) {
                  notification.Status(150, this);
                } else notification.Status("errorMsg", this, error.errMsg)
              } else {
                //notification.Status(503, this)
              }
              this.loading = false;
            })
          }).catch((e) => {
          })
        } else {
          for (var k in errors) {
            errors[k].forEach((element) => {
              notification.Status("warning", this, element.message);
            })
            this.loading = false
          }
        }
      })
    },
  }
}

function editSozlesmeList(sozlesme) {
  const sozlesmeSirasi = [];

  sozlesme.forEach(element => {
    element.sozlesmeler.forEach((sozlesme, i, array) => {
      const durumID = sozlesme.sozlesmeID;
      const sonrakiSozlesmeID = i === array.length - 1 ? 0 : array[i + 1].sozlesmeID;
      const kategoriID = sozlesme.sozlesmeKategoriID;
      sozlesmeSirasi.push({kategoriID, durumID, sonrakiSozlesmeID});
    });
  });
  //console.log(sozlesmeSirasi)

  return sozlesmeSirasi;
}


</script>


<style scoped lang="scss">
@import '../../../assets/scss/_variables';

.el-radio-group {
  .el-radio {
    margin: 1% 0px 0px 4%;
    display: block !important;
  }
}

.rightHeaderText {
  // font-weight: bold !important;
  font-size: 22px !important;
}

.rightContainerHeader {
  position: relative;
  border-radius: 5px;
  border-bottom: 3px solid $text-color-accent;
  margin-bottom: 5px;
  background-color: $text-color-accent;

  div {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
  }
}

.my-sticky {
  position: sticky !important;
}

</style>

